// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("/opt/build/repo/src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-category-js": () => import("/opt/build/repo/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-subcategory-js": () => import("/opt/build/repo/src/templates/subcategory.js" /* webpackChunkName: "component---src-templates-subcategory-js" */),
  "component---src-templates-section-js": () => import("/opt/build/repo/src/templates/section.js" /* webpackChunkName: "component---src-templates-section-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cooperation-js": () => import("/opt/build/repo/src/pages/cooperation.js" /* webpackChunkName: "component---src-pages-cooperation-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

